import AuthUserContext from "./context";
import React from "react";
import { getUnixTime } from "date-fns";
import { withFirebase } from "../Firebase";

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    _initFirebase = false;

    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
      };
    }

    firebaseInit = () => {
      if (this.props.firebase && !this._initFirebase) {
        this._initFirebase = true;

        this.listener = this.props.firebase.onAuthUserListener(
          (authUser) => {
            localStorage.setItem("authUser", JSON.stringify(authUser));
            this.setState({ authUser });
            this.props.firebase
              .getCurrentUserTokenResult()
              .then((result) => {
                const now = new Date();
                const goal_start_date =
                  authUser.goal_start_date || getUnixTime(now);
                this.props.firebase.user(authUser.uid).set(
                  {
                    last_accessed: now.toString(),
                    last_accessed_timestamp: getUnixTime(now),
                    goal_start_date,
                    goal_last0: authUser.goal_last0 || 0,
                    goal_last1: authUser.goal_last1 || 0,
                    goal_last2: authUser.goal_last2 || 0,
                    goal_last3: authUser.goal_last3 || 0,
                    goal_last4: authUser.goal_last4 || 0,
                    goal_last5: authUser.goal_last5 || 0,
                    goal_count0: authUser.goal_count0 || 0,
                    goal_count1: authUser.goal_count1 || 0,
                    goal_count2: authUser.goal_count2 || 0,
                    goal_count3: authUser.goal_count3 || 0,
                    goal_count4: authUser.goal_count4 || 0,
                    goal_count5: authUser.goal_count5 || 0,
                    goal_lock0: authUser.goal_lock0 || 0,
                    goal_lock1: authUser.goal_lock1 || 0,
                    goal_lock2: authUser.goal_lock2 || 0,
                    goal_lock3: authUser.goal_lock3 || 0,
                    goal_lock4: authUser.goal_lock4 || 0,
                    goal_lock5: authUser.goal_lock5 || 0,
                  },
                  { merge: true }
                );
              })
              .catch((err) => {
                console.log(err);
              });
          },
          () => {
            localStorage.removeItem("authUser");
            this.setState({ authUser: null });
          }
        );
      }
    };

    componentDidMount() {
      // Left in componentDidMount because needs window.localStorage
      // Cannot be done in the constructor due to SSR
      this.setState({ authUser: JSON.parse(localStorage.getItem("authUser")) });
      this.firebaseInit();
    }

    componentDidUpdate() {
      this.firebaseInit();
    }

    componentWillUnmount() {
      if (this.listener) {
        this.listener();
      }
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
