import React from "react";
import { navigate, Link } from "gatsby";
import { Location } from "@reach/router";
import { ModalRoutingContext } from "gatsby-plugin-modal-routing-3";
import AuthUserContext from "./context";
import { withFirebase } from "../Firebase/context";
import * as ROUTES from "../../constants/routes";

const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    _initFirebase = false;

    firebaseInit = () => {
      if (this.props.firebase && !this._initFirebase) {
        this._initFirebase = true;

        this.listener = this.props.firebase.onAuthUserListener(
          (authUser) => {
            if (!condition(authUser)) {
              navigate(ROUTES.SIGN_IN, {
                state: { continueUrl: this.props.location.pathname },
              });
            }
          },
          () =>
            navigate(ROUTES.SIGN_IN, {
              state: { continueUrl: this.props.location.pathname },
            })
        );
      }
    };

    componentDidMount() {
      this.firebaseInit();
    }

    componentDidUpdate() {
      this.firebaseInit();
    }

    componentWillUnmount() {
      this.listener && this.listener();
    }

    render() {
      const componentWithProps = <Component {...this.props} />;
      return (
        <ModalRoutingContext.Consumer>
          {({ modal, closeTo }) =>
            modal ? (
              <div className="modal-page">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "25px",
                  }}
                >
                  <Link
                    to={closeTo}
                    state={{
                      noScroll: true,
                    }}
                    className="light-link"
                  >
                    <strong>Close Window X</strong>
                  </Link>
                </div>
                <div>{componentWithProps}</div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "25px",
                  }}
                >
                  <Link
                    to={closeTo}
                    state={{
                      noScroll: true,
                    }}
                    className="light-link"
                  >
                    <strong>Close Window X</strong>
                  </Link>
                </div>
              </div>
            ) : (
              <AuthUserContext.Consumer>
                {(authUser) =>
                  condition(authUser) ? componentWithProps : null
                }
              </AuthUserContext.Consumer>
            )
          }
        </ModalRoutingContext.Consumer>
      );
    }
  }

  return withFirebase((props) => {
    return (
      <Location>
        {({ location }) => <WithAuthorization {...props} location={location} />}
      </Location>
    );
  });
};

export default withAuthorization;
