export const LANDING = "/";
export const HOME = "/";
export const SIGN_UP = "/signup/";
export const SIGN_IN = "/signin/";
export const SIGN_OUT = "/signout/";
export const ACCOUNT = "/account/";
export const CANCELED = "/canceled/";
export const PASSWORD_FORGET = "/pw-forget/";
export const ADMIN = "/kl-admin/";
export const ADMIN_DETAILS = "/admin/:id";
export const MEMBERSHIP = "/membership/";
export const UPDATE_PAYMENT = "/update-payment/";
export const EXERCISE_LIBRARY = "/exercise-library/";
export const TRAINING_VIDEOS = "/training-videos/";
export const FAQ = "/faq/";
export const GETTING_STARTED = "/getting-started/";
export const BALANCE_TRACKER = "/balance-tracker/";
export const BLOG = "/blog/";
export const CONTACT = "/contact/";
export const WAIVER = "/waiver/";
export const TERMS = "/terms/";
export const LOGBOOK = "/logbook";
export const FEATURE_WEEKLY_WORKOUTS = "/features/weekly-workouts/";
export const FEATURE_WORKOUT_LIBRARY = "/features/workout-library/";
export const FEATURE_BALANCED_LIFESTYLE =
  "/features/balanced-lifestyle-guidance/";
export const FEATURE_COMMUNITY_SUPPORT = "/features/balance-community-support/";
export const FEATURE_PERSONAL_MEETINGS =
  "/features/personal-meetings-with-kristie/";
export const PRINT = "/print";
export const FOREVER_BALANCE = "/forever-balance/";

export const STRIPE_BUY_NUTRITION_PLAN =
  "https://buy.stripe.com/7sIbMO2xQ8VT56E288";
export const CALENDLY_FREE_BALANCE_CONSULTATION =
  "https://calendly.com/kristielengel/balance-consultation";

export const CALENDLY_FOREVER_BALANCE_CONSULTATION =
  "https://calendly.com/kristielengel/forever-balance-consultation";
export const BALANCE_BOOT_CAMP = "/balance-boot-camp";
export const PODCAST = "https://balancebanter.buzzsprout.com";
export const PUSH_NOTIFICATIONS = "/push-notifications";
export const BALANCE_PODCAST = "/balance-banter-podcast";
