import React from 'react'
import AuthUserContext from './context';

export default function useAuthUser() {  
  const context = React.useContext(AuthUserContext);
  // if (!context) {
  //   throw new Error("useAuthUser must be used with an AuthUserContext");
  // }

  return context;
}
