import React from "react";
import { navigate } from "gatsby";

import AuthUserContext from "./context";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

const withActiveSubscription = (Component) => {
  class WithActiveSubscription extends React.Component {
    _initFirebase = false;

    firebaseInit = () => {
      if (this.props.firebase && !this._initFirebase) {
        this._initFirebase = true;

        this.listener = this.props.firebase.onAuthUserListener(
          (authUser) => {
            const { subscription_status } = authUser;
            // if (!subscription_status || subscription_status === 'incomplete') {
            if (
              subscription_status &&
              (subscription_status === "canceled" ||
                subscription_status === "unpaid")
            ) {
              navigate(ROUTES.CANCELED);
            }
          },
          () => navigate(ROUTES.CANCELED)
        );
      }
    };

    componentDidMount() {
      this.firebaseInit();
    }

    componentDidUpdate() {
      this.firebaseInit();
    }

    componentWillUnmount() {
      this.listener && this.listener();
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) => {
            const { subscription_status } = authUser;

            return authUser && subscription_status &&
              (subscription_status !== "canceled" ||
                subscription_status !== "unpaid") ? (
              <Component {...this.props} />
            ) : null;
          }}
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithActiveSubscription);
};

export default withActiveSubscription;
