import React from "react";

const FirebaseContext = React.createContext(null);

export const withFirebase = (Component) => (props) => (
  <FirebaseContext.Consumer>
    {(firebase) => <Component {...props} firebase={firebase} />}
  </FirebaseContext.Consumer>
);

export function useFirebase() {
  const context = React.useContext(FirebaseContext);

  // if (!context) {
  //   throw new Error(
  //     `useFirebase must be used within a FirebaseContext Provider`
  //   );
  // }

  return context;
}

export default FirebaseContext;
