const config = {
  apiKey: process.env.GATSBY_API_KEY,
  authDomain: process.env.GATSBY_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_DATABASE_URL,
  projectId: process.env.GATSBY_PROJECT_ID,
  storageBucket: process.env.GATSBY_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor(app) {
    app.initializeApp(config);

    /* Helper */

    this.fieldValue = app.firestore.FieldValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.firestore();

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.GATSBY_CONFIRMATION_EMAIL_REDIRECT,
    });

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  getCurrentUserTokenResult = () => this.auth.currentUser.getIdTokenResult();

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then((snapshot) => {
            const dbUser = snapshot.data();

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***

  user = (uid) => this.db.doc(`users/${uid}`);

  users = () => this.db.collection("users");

  // *** Message API ***

  message = (uid) => this.db.doc(`messages/${uid}`);

  messages = () => this.db.collection("messages");

  // *** Threads API ***

  thread = (id) => this.db.doc(`threads/${id}`);

  threads = () => this.db.collection("threads");

  // *** Comments API ***

  comments = (id) =>
    this.db
      .collection("threads")
      .doc(id)
      .collection("comments");

  comment = (threadId, id) =>
    this.db
      .collection("threads")
      .doc(threadId)
      .collection("comments")
      .doc(id);

  // *** PromoCodes API ***
  promoCode = (id) => this.db.collection("promo_codes").doc(id);

  promoCodes = () => this.db.collection("promo_codes");

  // *** FieldValue ***

  fieldValue = () => this.fieldValue;

  // *** Measurements API ***
  measurements = (id) =>
    this.db
      .collection("data_points")
      .doc(id)
      .collection("measurements");

  // *** Workouts API ***
  workouts = (id) =>
    this.db
      .collection("data_points")
      .doc(id)
      .collection("workouts");

  workoutsForDateRange = (id, startDate, endDate) =>
    this.db
      .collection("data_points")
      .doc(id)
      .collection("workouts")
      .where("date", ">=", startDate)
      .where("date", "<=", endDate);

  workout = (uid, id) =>
    this.db
      .collection("data_points")
      .doc(uid)
      .collection("workouts")
      .doc(id);

  ratings = () => this.db.collection("ratings");

  rating = (id) => this.db.collection("ratings").doc(id);

  ratingForUserWorkout = (uid, description, value) =>
    this.db
      .collection("ratings")
      .where("uid", "==", uid)
      .where("description", "==", description)
      .where("value", "==", value)
      .limit(1);

  // *** Goals API ***
  goalsForDateRange = (id, startOfMonth, endOfMonth) =>
    this.db
      .collection("data_points")
      .doc(id)
      .collection("goals")
      .where("date", ">=", startOfMonth)
      .where("date", "<=", endOfMonth);
}

let firebase;

function getFirebase(app, auth, database) {
  if (!firebase) {
    firebase = new Firebase(app, auth, database);
  }

  return firebase;
}

export default getFirebase;
